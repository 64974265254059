<template>
    <div class="options-form">
        <div class="options-form__headings" v-if="productOptions.length">
            <div class="options-form__heading">{{ $t('entities.option.fields.position') }}</div>
            <div class="options-form__heading">{{ $t('entities.option.fields.title') }}</div>
            <div class="options-form__heading">{{ $t('entities.option.fields.values') }}</div>
        </div>
        <draggable
            v-model="productOptions"
            handle=".options-form__drag-handle"
            tag="div"
            :animation="200"
            drag-class="ghost"
            chosen-class="ghost"
            ghost-class="ghost"
            class="options-form__draggable"
            :move="handleDragMove"
        >
            <div
                class="options-form__row"
                :data-id="option.id"
                v-for="(option, index) in productOptions"
                :key="option.id"
            >
                <div class="options-form__drag-handle">
                    <span class="material-icons-outlined ">drag_indicator</span>
                </div>
                <form-item class="options-form__item" no-label>
                    <input-text @input="onUpdatePosition(option)" v-model="option.entry.position"> </input-text>
                </form-item>

                <form-item class="options-form__item" no-label>
                    <combobox
                        :value="option.option.id"
                        :items="options"
                        :items-to-ignore="selectedOptions"
                        item-text="title"
                        name="name"
                        item-value="id"
                        @input="onChangeOption($event, index)"
                    />
                </form-item>

                <form-item class="options-form__item" :errors="valuesErrors[option.id]" no-label>
                    <combobox-multiple
                        :sort="false"
                        name="values"
                        :items="productOptionsValuesItems[option.id]"
                        :item-text="(item) => item.optionValue.title"
                        :value="option.values"
                        draggable
                        @drag-end="handleValuesDrag($event, option)"
                        :disabled="!option.option.title"
                        :errors="valuesErrors[option.id]"
                        :itemsType="option.option.type"
                        @updateTypeErrors="handleValuesType($event, option.id)"
                        @input="onChangeOptionValue($event, option)"
                        item-value="id"
                    />
                </form-item>

                <Button
                    class="options-form__item"
                    @click="onRemoveProductOption(index)"
                    type="icon"
                    icon="delete"
                ></Button>
            </div>
        </draggable>
        <Button class="options-form__add-btn" icon="add_circle_outline" @click="onClickAddNewOption">{{
            $t('lists.addButton.option')
        }}</Button>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, uniqBy } from 'lodash';
import ProductOption from '@/entities/product/ProductOption';
import ProductOptionValue from '@/entities/product/ProductOptionValue';
import ProductOptionEntry from '@/entities/product/ProductOptionEntry';
import Option from '@/entities/option/Option';
import { handleOptionValues } from '@/entities/product/ProductOptionHelper';
import { changePosition } from '@/helpers/utils';
import Combobox from '@/components/form/controls/Combobox';
import ComboboxMultiple from '@/components/form/controls/ComboboxMultiple';
import InputText from '@/components/form/controls/InputText';
import FormItem from '@/components/form/item';
import draggable from 'vuedraggable';

export default {
    name: 'ProductOptionsList',
    components: {
        FormItem,
        InputText,
        ComboboxMultiple,
        Combobox,
        Button,
        draggable,
    },
    created() {
        this.optionsEnabled = this.value.length;
        this.productOptions = this.value.map((entry) => {
            const relatedOption = this.options.find((option) => {
                return option.id === entry.optionId;
            });
            return new ProductOption({ entry: cloneDeep(entry), option: cloneDeep(relatedOption) });
        });
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            productOptions: null,
            optionsEnabled: null,
            valuesErrors: {},
        };
    },
    computed: {
        ...mapGetters('product', {
            options: 'getOptionsList',
            product: 'getCurrent',
        }),
        selectedOptions() {
            return this.options.filter((option) => {
                return this.productOptions.find((productOption) => productOption.option.id === option.id);
            });
        },
        productOptionsValuesItems() {
            const items = this.productOptions.map((option) => {
                const newValues = option.option.values.map((value) => new ProductOptionValue({ optionValue: value }));
                return [option.id, uniqBy([...option.values, ...newValues], 'optionValue.title')];
            });
            return Object.fromEntries(items);
        },
    },
    methods: {
        ...mapActions('product', {
            updateOptionsToUpdate: 'updateOptionsToUpdate',
        }),
        onClickAddNewOption() {
            const entry = new ProductOptionEntry();
            const option = new Option();
            entry.position = this.productOptions.length;
            this.productOptions.push(
                new ProductOption({
                    entry,
                    option,
                })
            );
        },
        handleValuesType(items, optionId) {
            if (items.length) {
                this.$set(this.valuesErrors, optionId, [this.$t('validator.errors.optionValuesTypeNumber')]);
            } else {
                this.$delete(this.valuesErrors, optionId);
            }
        },

        handleValuesDrag(values, option) {
            option.entry.values = values.map((item, index) => {
                item.entryValue.position = index;
                return item;
            });
            this.onChangeOptionValue(values, option);
        },

        onChangeOption(value, index) {
            if (!value) return;
            const newOption = typeof value === 'object' ? cloneDeep(value) : new Option({ title: value });
            this.productOptions[index].option = newOption;
            this.productOptions[index].values = [];
        },
        onChangeOptionValue(values, option) {
            //проверяет существует ли ли уже выбраное значение и если нет, то создаёт новое
            let optionValues = handleOptionValues(values, option, this.productOptionsValuesItems);
            optionValues = uniqBy(optionValues, 'optionValue.title');
            optionValues.forEach((item, index) => (item.entryValue.position = index));
            option.values = optionValues;
            option.entry.values = optionValues.map((value) => value.entryValue);
            const initialOption = this.options.find((item) => {
                return item.id === option.option.id;
            });
            //добавляем только новые значения для сохранения
            //если существующая опция
            if (initialOption) {
                const newOptionValues = optionValues
                    .filter((item) => {
                        return !initialOption.values.find((value) => {
                            return value.id === item.optionValue.id;
                        });
                    })
                    .map((item) => item.optionValue);
                if (newOptionValues.length) {
                    option.option.values = [...initialOption.values, ...newOptionValues];
                }
            }
            //Если новая опция
            else {
                option.option.values = optionValues.map((item) => item.optionValue);
            }
            if (!optionValues.length) {
                this.onRemoveProductOption(this.productOptions.findIndex((item) => item.optionId === option.id));
            }
        },
        onRemoveProductOption(index) {
            this.productOptions.splice(index, 1);
            this.productOptions.forEach((item, index) => {
                item.entry.position = index;
            });
        },

        saveOptions() {
            this.updateOptionsToUpdate(this.productOptions.map(({ option }) => cloneDeep(option).data));
            const optionEntries = this.productOptions.map(({ entry }) => entry);
            this.$emit('input', cloneDeep(optionEntries));
            this.$emit('update-field', {
                name: this.name,
                value: cloneDeep(optionEntries),
            });
            this.$emit('update-variations', this.productOptions);
        },

        onUpdatePosition(option) {
            changePosition(option, this.productOptions, this.productOptions, 'entry.position');
        },

        handleDragMove(event) {
            this.$nextTick(() => {
                const rows = event.to.children;
                for (let i = 0; i < rows.length; i++) {
                    const option = this.productOptions.find((option) => option.id === rows[i].dataset.id);
                    option.entry.position = i;
                }
            });
        },
    },
    watch: {
        productOptions: {
            deep: true,
            handler(newProductOptions, oldProductOptions) {
                if (!oldProductOptions) return; // Если это первое изменение(например загрузка страницы), то ничего делать не надо
                this.saveOptions();
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.options-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__draggable {
        display: flex;
        flex-direction: column;
        .ghost {
            cursor: grabbing !important;
            transform: scale(1.025) !important;
            box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s;
            z-index: 300;
            background-color: var(--v-primary-lighten-base);
        }
    }
    &__row {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 8px 0 16px 8px;
        width: 100%;
    }
    &__drag-handle {
        cursor: pointer;
        &:active {
            cursor: grabbing;
        }
        .material-icons-outlined {
            font-size: 18px;
        }
    }
    &__item {
        width: auto;
        &:nth-child(2) {
            flex-basis: 10%;
            min-width: 60px;
        }
        &:nth-child(3) {
            flex-basis: 35%;
            min-width: 150px;
        }
        &:nth-child(4) {
            flex-basis: 75%;
        }
        &:last-child {
            width: 5%;
        }
    }
    &__headings {
        gap: 20px;
        padding-left: 8px;
        display: flex;
    }
    &__heading {
        text-align: left;
        padding-left: 2px;
        &:first-child {
            flex-basis: 10%;
            min-width: 60px;
            margin-left: 38px;
        }
        &:nth-child(2) {
            flex-basis: 35%;
            min-width: 150px;
        }
        &:nth-child(3) {
            flex-basis: 75%;
            margin-right: calc(5% + 20px);
        }
    }
    &__add-btn {
        min-width: 64px;
        width: fit-content;
    }
}
</style>
