<template>
    <div v-click-outside="closeDropdown" :name="name" @click="activate" class="combobox" :class="classes">
        <div class="combobox__content">
            <template v-if="!isActive">{{ title.text }}</template>
            <input
                v-else
                class="combobox__field"
                @keydown.enter="addNewItem"
                v-model="text"
                :class="title.type"
                ref="input"
            />
            <span class="combobox__arrow material-symbols-outlined" :class="{ 'is-open': isDropdownShow }">
                expand_more
            </span>
        </div>
        <div class="combobox__dropdown" :class="{ 'is-open': isDropdownShow }">
            <div
                v-for="option in searched"
                :key="option.id"
                class="combobox__option"
                :class="{ '-selected': checkIfSelected(option) }"
                @click.stop="selectOption(option)"
            >
                <div class="option__text" v-html="highlight(option.text)"></div>
            </div>
            <div class="combobox__option" v-if="!searched.length">{{ $t('search.noData') }}</div>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { cloneDeep, isEqual } from 'lodash';
import { uuid } from 'vue-uuid';

export default {
    name: 'Combobox',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'Choose' },
        errors: { type: [Array, Object], default: () => [] },
        items: { type: [Array], default: () => [] },
        itemsToIgnore: { type: Array, default: () => [] },
        value: { type: [Array, String, Number, Object] },
        itemValue: { type: [String, Function], default: 'value' },
        itemText: { type: [String, Function], default: 'text' },
        returnObject: { type: Boolean, default: true },
        sort: { type: Boolean, default: true },
    },

    data() {
        return {
            isDropdownShow: false,
            options: [],
            selected: null,
            isActive: false,
            text: '',
        };
    },
    computed: {
        isNewItem() {
            return !this.items.find((item) => {
                const text = typeof this.itemText === 'function' ? this.itemText(item) : item[this.itemText] || item;
                return text === this.text;
            });
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-is-open': this.isDropdownShow,
            };
        },
        searched() {
            if (!this.text)
                return this.options.filter((item) => {
                    return (
                        this.selected?.id !== item.id &&
                        !this.itemsToIgnore.find((itemToIgnore) => itemToIgnore[this.itemValue] === item.value)
                    );
                });
            return this.options.filter((item) => {
                if (this.selected?.id === item.id) return false;
                return item.text.toLowerCase().startsWith(this.text.toLowerCase());
            });
        },
        title() {
            if (this.selected) return { type: '-value', text: this.$t(this.selected.text) };
            if (this.placeholder) return { type: '-placeholder', text: this.$t(this.placeholder) };
            return { type: '-placeholder', text: this.name };
        },
    },

    methods: {
        highlight(text) {
            if (!this.text) {
                return text;
            }
            return text.replace(new RegExp(this.text, 'gi'), (match) => {
                return '<span class="highlight">' + match + '</span>';
            });
        },
        checkIfSelected(option) {
            return option.id === this.selected?.id;
        },
        addNewItem() {
            if (!this.text || !this.isNewItem) return;
            const newOption = {
                id: uuid.v4(),
                text: this.text,
                value: this.text,
                item: this.text,
            };
            this.options.push(cloneDeep(newOption));
            this.text = '';
            this.selectOption(newOption);
        },
        activate() {
            this.isActive = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
                if (!this.isDropdownShow) {
                    this.openDropdown();
                }
            });
        },
        deactivate() {
            if (this.text && this.isNewItem) this.addNewItem();
            this.isActive = false;
        },
        formatItems(options) {
            if (!options) return [];
            const result = options.map((item) => ({
                id: uuid.v4(),
                text: typeof this.itemText === 'function' ? this.itemText(item) : item?.[this.itemText] || item,
                value: typeof this.itemValue === 'function' ? this.itemValue(item) : item?.[this.itemValue] || item,
                item: cloneDeep(item),
            }));
            if (this.sort) {
                return result.sort((a, b) => `${a.text}`.localeCompare(b.text));
            }
            return result;
        },

        formatSelected(value) {
            return this.options.find((option) => {
                return isEqual(option.item[this.itemValue], value);
            });
        },

        selectOption(option) {
            this.text = '';
            if (this.returnObject) {
                this.model = option.item;
            } else {
                this.model = option.value;
            }
            this.selected = option;
            this.closeDropdown();
        },

        openDropdown() {
            this.isDropdownShow = true;
        },
        closeDropdown() {
            if (!this.isDropdownShow) return;
            this.isDropdownShow = false;
            this.deactivate();
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
        items: {
            handler(newValue) {
                this.options = this.formatItems(newValue);
                this.selected = this.formatSelected(this.value);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.combobox {
    position: relative;

    padding: 0 $form-control-padding-horizontal;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    background: $form-control-bg;
    transition: $form-control-transition;

    .icon {
        font-size: 24px;
    }

    .option__text {
        padding-left: 15px;
        .highlight {
            background-color: var(--v-on-primary-medium-base);
        }
    }
    input {
        border: none;
    }

    &__content {
        min-height: 38px;
        height: auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        padding: 7px 0;
        margin-right: 25px;
    }

    &:hover,
    &:focus {
        border-color: $form-control-border-color-hover;
    }
    &.-is-open {
        z-index: 5;
    }
    cursor: pointer;

    &__field {
        display: block;
        height: 24px;
        outline: none !important;
        appearance: none;
        width: 100%;
        flex-shrink: 2;
        line-height: calc(#{$form-control-height} - 2px);
        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    &.-has-errors &__field {
        border-color: $error;
    }

    &__arrow {
        position: absolute;
        right: 8px;
        top: 9px;
        translate: $transition-fast;
        pointer-events: none;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;
        max-height: 300px;
        overflow-y: auto;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }

        &.-top {
            bottom: 100%;
            top: auto;
            transform-origin: 0% 100%;
        }
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 40px 8px 12px;
        transition: $transition-fast;
        font-size: 16px;
        line-height: 32px;
        text-align: left;
        cursor: pointer;

        &.-selected {
            background-color: var(--v-primary-lighten-base);
        }
        &-icon {
            position: relative;
            top: 0;
            color: inherit;
            line-height: 24px;
            margin-right: 10px;
        }

        &:hover {
            background-color: #f4f6f7;
        }

        &.-current {
            border-bottom: 1px solid #e0e0e0;
        }

        &.-highlighted {
            color: #2979ff;
        }
    }
}

.icon {
    &.-selected {
        color: var(--v-primary-base) !important;
    }
}
</style>
