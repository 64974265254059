
import Vue from 'vue';
export default Vue.extend({
    name: 'EditableText',
    props: {
        text: { type: String, default: '' },
    },
    data() {
        return {
            textModel: this.text as string,
            isEditing: false as boolean,
        };
    },
    computed: {
        size(): number {
            if (this.textModel.length - 3 > 0) {
                return this.textModel.length - 3;
            }
            return 1;
        },
    },
    methods: {
        startEditing(): void {
            this.textModel = this.text;
            this.isEditing = true;
            this.$nextTick(() => {
                const input = this.$refs['editing-input'] as HTMLInputElement;
                input.focus();
            });
            this.$emit('start-editing');
        },
        cancelEditing(): void {
            this.isEditing = false;
            this.$emit('cancel-editing');
        },
        save(): void {
            if (!this.textModel) return;
            this.$emit('save', this.textModel);
            this.isEditing = false;
        },
    },
});
