<template>
    <div class="properties-form">
        <div class="properties-form__headings" v-if="productProperties.length">
            <div class="properties-form__heading">{{ $t('entities.property.fields.position') }}</div>
            <div class="properties-form__heading">{{ $t('entities.property.fields.icon') }}</div>
            <div class="properties-form__heading">{{ $t('entities.property.fields.title') }}</div>
            <div class="properties-form__heading">{{ $t('entities.property.fields.values') }}</div>
        </div>
        <draggable
            v-model="productProperties"
            handle=".properties-form__drag-handle"
            tag="div"
            :animation="200"
            drag-class="ghost"
            chosen-class="ghost"
            ghost-class="ghost"
            class="properties-form__draggable"
            :move="handleDragMove"
        >
            <div
                class="properties-form__row"
                v-for="(property, index) in productProperties"
                :data-id="property.id"
                :key="property.id"
            >
                <div class="properties-form__drag-handle">
                    <span class="material-icons-outlined ">drag_indicator</span>
                </div>
                <form-item class="properties-form__item" no-label>
                    <input-text @input="onUpdatePosition(property)" v-model="property.position"> </input-text>
                </form-item>
                <form-item class="properties-form__item -icon" no-label>
                    <span class="material-icons-outlined" v-if="property.icon">{{ property.icon }}</span>
                    <span class="icon__placeholder" v-else>—</span>
                </form-item>
                <form-item class="properties-form__item" no-label>
                    <combobox
                        :value="property.propertyId"
                        :items="allProperties"
                        :items-to-ignore="selectedProperties"
                        item-text="title"
                        item-value="id"
                        name="name"
                        return-object
                        @input="onChangeProperty($event, index)"
                    />
                </form-item>

                <form-item class="properties-form__item" no-label :errors="valuesErrors[property.id]">
                    <combobox
                        name="value"
                        :sort="false"
                        :items="productPropertiesValuesItems[property.propertyId]"
                        item-text="title"
                        draggable
                        :value="property.value.propertyValueId"
                        :disabled="!property.title"
                        :errors="valuesErrors[property.id]"
                        return-object
                        @input="onChangePropertyValue($event, property)"
                        item-value="id"
                    />
                </form-item>

                <Button
                    class="properties-form__item"
                    @click="onRemoveProductProperty(index)"
                    type="icon"
                    icon="delete"
                ></Button>
            </div>
        </draggable>
        <Button class="properties-form__add-btn" icon="add_circle_outline" @click="onClickAddNewProperty">{{
            $t('lists.addButton.property')
        }}</Button>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, uniqBy } from 'lodash';
import Property from '@/entities/property/Property';
import PropertyValue from '@/entities/property/PropertyValue';
//import { handlePropertyValues } from '@/entities/product/ProductPropertyHelper';
import { changePosition } from '@/helpers/utils';
import Combobox from '@/components/form/controls/Combobox';
import InputText from '@/components/form/controls/InputText';
import FormItem from '@/components/form/item';
import draggable from 'vuedraggable';

export default {
    name: 'ProductPropertiesList',
    components: {
        FormItem,
        InputText,
        Combobox,
        Button,
        draggable,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        catalogs: {
            type: Array,
            default: () => [],
        },
    },
    created() {
        this.allProperties = cloneDeep(this.properties);
    },
    data() {
        return {
            productProperties: [],
            valuesErrors: {},
            productPropertiesValue: null,
            propertiesToUpdate: [],
            allProperties: [],
        };
    },
    computed: {
        ...mapGetters('product', {
            properties: 'getPropertiesList',
        }),
        selectedProperties() {
            return this.allProperties.filter((property) => {
                return this.productProperties.find((productProperty) => productProperty.propertyId === property.id);
            });
        },
        productPropertiesValuesItems() {
            const items = this.productProperties.map((productProperty) => {
                let property =
                    this.propertiesToUpdate.find((property) => property.id === productProperty.propertyId) ||
                    this.allProperties.find((property) => property.id === productProperty.propertyId);
                if (!property) {
                    return [productProperty.id, []];
                }
                return [property.id, uniqBy([...property.values], 'title')];
            });
            return Object.fromEntries(items);
        },
    },
    methods: {
        ...mapActions('product', {
            updatePropertiesToUpdate: 'updatePropertiesToUpdate',
        }),
        onClickAddNewProperty() {
            const productProperty = new Property({ value: {}, position: this.productProperties.length }, true);
            this.productProperties.push(productProperty);
        },

        onChangeProperty(value, index) {
            if (!value) return;
            let newProperty;
            let productProperty;
            //если выбрали из сушествующих
            if (typeof value === 'object') {
                productProperty = new Property(
                    { title: value.title, propertyId: value.id, icon: value.icon, position: index },
                    true
                );
            }
            //если создаём новую
            else {
                newProperty = new Property({ title: value });
                productProperty = new Property({ title: value, propertyId: newProperty.id, position: index }, true);
            }
            const newProductProperties = cloneDeep(this.productProperties);
            newProductProperties[index] = productProperty;
            if (newProperty) {
                this.propertiesToUpdate.push(newProperty);
                this.allProperties.push(newProperty);
            }
            this.productProperties = newProductProperties;
        },
        onChangePropertyValue(value, property) {
            let newProductValue;
            let newValue;
            //если выбрали из существующих
            if (typeof value === 'object') {
                newProductValue = new PropertyValue(
                    { id: property.value.id, propertyValueId: value.id, title: value.title },
                    true
                );
            } else {
                const searchedValue = this.productPropertiesValuesItems[property.propertyId].find(
                    (valueItem) => valueItem?.title === value
                );
                //если ввели название значения руками и такая опция есть - возвращаем его

                if (searchedValue) {
                    newProductValue = new PropertyValue(
                        { propertyValueId: searchedValue.id, title: searchedValue.title },
                        true
                    );
                }
                //если нет - создаём новое
                else {
                    newValue = new PropertyValue({ title: value });
                    newProductValue = new PropertyValue({ title: value, propertyValueId: newValue.id }, true);
                    const existingProperty = this.properties.find((item) => item.id === property.propertyId);
                    if (existingProperty) {
                        const property = cloneDeep(existingProperty);
                        property.values = [...property.values, newValue];
                        const propertyToUpdateIndex = this.propertiesToUpdate.findIndex(
                            (item) => item.id === property.id
                        );
                        if (propertyToUpdateIndex !== -1) {
                            this.propertiesToUpdate[propertyToUpdateIndex] = property;
                        } else {
                            this.propertiesToUpdate.push(property);
                        }
                    } else {
                        const propertyToUpdate = this.propertiesToUpdate.find(
                            (item) => item.id === property.propertyId
                        );
                        if (propertyToUpdate) {
                            propertyToUpdate.values = [newValue];
                        }
                    }
                }
            }
            property.value = newProductValue;
        },
        onRemoveProductProperty(index) {
            this.productProperties = this.productProperties
                .filter((item, itemIndex) => index !== itemIndex)
                .map((item, index) => {
                    item.position = index;
                    return item;
                });
        },

        saveProperties() {
            this.updatePropertiesToUpdate(this.propertiesToUpdate.map((property) => property.data));
            this.$emit('input', cloneDeep(this.productProperties));
            this.$emit('update-field', {
                name: this.name,
                value: cloneDeep(this.productProperties),
            });
        },
        handleDragMove(event) {
            this.$nextTick(() => {
                const rows = event.to.children;
                for (let i = 0; i < rows.length; i++) {
                    const property = this.productProperties.find((option) => option.id === rows[i].dataset.id);
                    property.position = i;
                }
            });
        },

        onUpdatePosition(property) {
            changePosition(property, this.productProperties, this.productProperties, 'position');
        },
    },
    watch: {
        productProperties: {
            deep: true,
            handler(newProductProperties, oldProductProperties) {
                if (!oldProductProperties) return; // Если это первое изменение(например загрузка страницы), то ничего делать не надо
                this.saveProperties();
            },
        },
        value: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!val) return;
                if (val.length !== this.productProperties.length) {
                    this.productProperties = this.value.map(
                        (item, index) => new Property({ ...item, position: item.position || index }, true)
                    );
                }
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.properties-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__draggable {
        display: flex;
        flex-direction: column;
        .ghost {
            cursor: grabbing !important;
            transform: scale(1.025) !important;
            box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s;
            z-index: 300;
            background-color: var(--v-primary-lighten-base);
        }
    }
    &__row {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 8px 0 16px 8px;
        width: 100%;
    }
    &__item {
        width: auto;
        &.-icon {
            display: flex;
            height: 100%;
            .material-icons-outlined,
            .icon__placeholder {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
            }
        }
        &:last-child {
            width: 5%;
        }
        &:nth-child(2) {
            flex-basis: 10%;
            min-width: 60px;
        }
        &:nth-child(3) {
            min-width: 60px;
            justify-content: start;
        }
        &:nth-child(4) {
            flex-basis: 60%;
        }
        &:nth-child(5) {
            flex-basis: 100%;
        }
    }
    &__headings {
        gap: 20px;
        padding-left: 8px;
        display: flex;
    }
    &__heading {
        text-align: left;
        &:first-child {
            flex-basis: 10%;
            min-width: 60px;
            margin-left: 38px;
        }
        &:nth-child(2) {
            min-width: 60px;
        }
        &:nth-child(3) {
            flex-basis: 60%;
        }
        &:last-child {
            flex-basis: 100%;
            margin-right: calc(5% + 20px);
        }
    }
    &__drag-handle {
        cursor: pointer;
        &:active {
            cursor: grabbing;
        }
        .material-icons-outlined {
            font-size: 18px;
        }
    }
    &__add-btn {
        min-width: 64px;
        width: fit-content;
    }
}
</style>
