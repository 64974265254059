<template>
    <div class="entity-item__block default-variation__block block">
        <div class="default-variation" :class="{ '-disabled': optionsEnabled }">
            <div class="default-variation__overlay"></div>
            <Table
                :show-toolbar="false"
                :show-selected-actions="false"
                class="variations-table"
                :show-select-all="false"
                :columns="headers"
                :items="[variation]"
            >
                <!-- TITLE -->
                <template v-slot:item.title="{ item }">
                    {{ item.title }}
                </template>
                <!-- STOCK -->
                <template v-slot:item.stock="{ item }">
                    <form-item v-bind="item.fields.stock.props">
                        <input-text
                            type="number"
                            v-bind="item.fields.stock.props"
                            :disabled="optionsEnabled"
                            @update-field="onUpdateFieldValue($event, item)"
                            name="stock"
                            v-model="item.stock"
                            null-value="∞"
                        ></input-text>
                    </form-item>
                </template>
                <!-- SKU -->
                <template v-slot:item.sku="{ item }">
                    <form-item v-bind="item.fields.sku.props">
                        <input-text
                            v-bind="item.fields.sku.props"
                            :disabled="optionsEnabled"
                            name="sku"
                            v-model="item.sku"
                            @update-field="onUpdateFieldValue($event, item)"
                        ></input-text>
                    </form-item>
                </template>
                <!-- FULL PRICE -->
                <template v-slot:item.fullPrice="{ item }">
                    <form-item v-bind="item.fields.fullPrice.props">
                        <input-text
                            :disabled="optionsEnabled"
                            :measurement="currency"
                            type="number"
                            name="fullPrice"
                            v-bind="item.fields.fullPrice.props"
                            @update-field="onUpdateFieldValue($event, item, true)"
                            v-model.number="item.fullPrice"
                        ></input-text>
                    </form-item>
                </template>
                <!-- SIZE -->
                <template v-slot:item.size="{ item }">
                    <form-item v-bind="item.fields.size.props">
                        <product-size-input
                            disabled
                            v-bind="item.fields.size.props"
                            name="size"
                            :default-value="product.size"
                            @input="updateVariationSize($event, item)"
                            @update-field="onUpdateFieldValue($event, item, true)"
                            :value="{ length: item.length, width: item.width, height: item.height }"
                        ></product-size-input>
                    </form-item>
                </template>
                <!-- WEIGHT -->
                <template v-slot:item.weight="{ item }">
                    <form-item v-bind="item.fields.weight.props">
                        <input-text
                            v-bind="item.fields.weight.props"
                            name="weight"
                            @update-field="onUpdateFieldValue($event, item)"
                            v-model="item.weight"
                        ></input-text>
                    </form-item>
                </template>
                <!-- DISCOUNT -->
                <template v-slot:item.discount="{ item }">
                    <form-item v-bind="item.fields.discount.props">
                        <input-text
                            :disabled="optionsEnabled"
                            name="discount"
                            v-bind="item.fields.discount.props"
                            @input.native="controlDiscountInput"
                            v-model="item.discount"
                        ></input-text>
                    </form-item>
                </template>
                <!-- PRICE AFTER DISCOUNT -->
                <!--                значение в fields хранится некорректное, но оно нам и не нужно-->
                <template v-slot:item.discountPrice="{ item }">
                    <form-item v-bind="item.fields.discountPrice.props">
                        <input-text
                            disabled
                            name="discountPrice"
                            @update-field="onUpdateFieldValue($event, item)"
                            :measurement="currency"
                            :value="item.getFinalPrice()"
                        ></input-text>
                    </form-item>
                </template>
                <!-- IMAGE -->
                <template v-slot:item.imageId="{ item }">
                    <product-image-field
                        :upload="false"
                        :owner-id="productId"
                        name="imageId"
                        image-type="product"
                        @update-field="onUpdateFieldValue($event, item)"
                        v-model="item.imageId"
                        is-popup
                    />
                </template>
                <!-- GTIN -->
                <template v-slot:item.gtin="{ item }">
                    <form-item v-bind="item.fields.gtin.props">
                        <input-text
                            v-bind="item.fields.gtin.props"
                            v-model="item.gtin"
                            :disabled="optionsEnabled"
                            @update-field="onUpdateFieldValue($event, item)"
                            name="gtin"
                        ></input-text>
                    </form-item>
                </template>
                <!-- STOCK HEADER -->
                <template v-slot:header.stock>
                    {{ $t('lists.columns.quantity') }}
                    <tooltip-popup>
                        <span>{{ $t('entities.productVariation.tooltips.quantity') }}</span>
                    </tooltip-popup>
                </template>
                <!-- SIZE HEADER -->
                <template v-slot:header.size>
                    <template>
                        {{ $t('lists.columns.size') }}
                        <tooltip-popup width="600" bottom>
                            <span v-html="$t('entities.product.tooltips.size')"></span>
                        </tooltip-popup>
                    </template>
                </template>
                <!-- DISCOUNT HEADER -->
                <template v-slot:header.discount>
                    {{ $t('lists.columns.discount') }}
                    <tooltip-popup>
                        <span>{{ $t('entities.productVariation.tooltips.discount') }}</span>
                    </tooltip-popup>
                </template>
                <template v-if="!productId" v-slot:footer>
                    <alert class="default-variation__alert">
                        {{ $t('entities.productVariation.tooltips.pictures') }}
                    </alert>
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import Table from '@/components/common/Table';
import FormItem from '@/components/form/item.vue';
import ProductImageField from '@/components/product/ProductImageField';
import InputText from '@/components/form/controls/InputText';
import ProductSizeInput from '@/components/product/ProductSizeInput';
import EventEmitter from '@/helpers/eventEmitter';
import { mapGetters } from 'vuex';
import Alert from '@/components/common/Alert';
import TooltipPopup from '@/components/common/TooltipPopup';
export default {
    name: 'DefaultVariation',
    components: { TooltipPopup, Alert, InputText, ProductImageField, Table, FormItem, ProductSizeInput },
    props: {
        value: {
            type: Array,
        },
        errors: {
            type: Array,
        },
        optionsEnabled: {
            type: Boolean,
        },
        name: {
            type: String,
        },
    },
    data() {
        return {
            variation: {},
            debouncedFieldHandler: null,
        };
    },
    methods: {
        onUpdateFieldValue(payload, variation, isDebounced = false) {
            if (isDebounced) {
                if (this.debouncedFieldHandler) {
                    this.debouncedFieldHandler.cancel();
                }
                this.debouncedFieldHandler = debounce(() => {
                    this.fieldHandler(payload, variation);
                    this.debouncedFieldHandler = null;
                }, 400);
                this.debouncedFieldHandler();
            } else {
                this.fieldHandler(payload, variation);
            }
        },

        fieldHandler(payload, item) {
            const { name } = payload;
            const newItem = cloneDeep(item);
            const errors = newItem.validateField(newItem.fields[name], newItem[name]);
            console.log(errors, payload.name, item, newItem.fields[name], newItem[name]);
            newItem.fields[name].props.errors = errors;
            const variations = cloneDeep(this.value);
            const index = variations.findIndex((variation) => variation.default);
            variations[index] = newItem;
            this.$emit('input', variations);
            this.$emit('update-field', {
                name: this.name,
                value: variations,
            });
        },

        controlDiscountInput(e) {
            const val = e.target.value;
            if (!val) return;
            const regEx = /(\d+\.\d{0,2}$)|(\d+\.\d{1,2}%?)|(\d+%?)/;
            const match = val.match(regEx);
            if (!match) this.variation.discount = '';
            else {
                this.variation.discount = match[0];
            }
            this.onUpdateFieldValue({ name: 'discount', value: val }, this.variation);
        },
        updateVariationSize(value, item) {
            const { length, height, width } = value;
            item.length = length;
            item.height = height;
            item.width = width;
            this.fieldHandler({ name: 'size' }, item);
        },
        showTooltips(tooltips) {
            EventEmitter.trigger('toggle-tooltips', {
                isShown: true,
                tooltips,
            });
        },
    },
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        productId() {
            return this.$route.params.id;
        },
        ...mapGetters('product', {
            product: 'getCurrent',
        }),
        headers() {
            const headers = [
                { text: this.$t('lists.columns.sku'), value: 'sku', width: '160px', sortable: false },
                { text: this.$t('lists.columns.fullPrice'), value: 'fullPrice', width: '130px', sortable: false },
                { value: 'discount', width: '130px', sortable: false },
                {
                    text: this.$t('lists.columns.priceAfterDiscount'),
                    value: 'discountPrice',
                    width: '130px',
                    sortable: false,
                },
                { value: 'stock', width: '130px', sortable: false },
                { text: this.$t('lists.columns.size'), value: 'size', width: '350px', sortable: false },
                {
                    text: this.$t('lists.columns.gtin'),
                    value: 'gtin',
                    width: '130px',
                    sortable: false,
                },
            ];
            if (this.productId) {
                headers.unshift({
                    text: this.$t('lists.columns.image'),
                    value: 'imageId',
                    sortable: false,
                    width: '30px',
                });
            }
            return headers;
        },
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(val) {
                const defaultVariation = val.find((variation) => variation.default);
                //При переключении defaultVariation массив вариаций обновляется дважды:
                // сначала у всех вариаций default = false, поэтому появляется ошибка у таблицы
                if (!defaultVariation) return;
                this.variation = cloneDeep(defaultVariation);
            },
        },

        errors: {
            deep: true,
            handler(val) {
                if (val?.length) {
                    for (const key in val[0].children) {
                        const field = this.variation.fields[key];
                        if (field) {
                            this.variation.fields[key].props.errors = val[0].children?.[key]?.errors;
                        }
                    }
                }
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.default-variation {
    position: relative;
    padding: 32px 24px;
    background-color: var(--v-surface-base);
    &__overlay {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--v-background-base);
        opacity: 0.5;
        position: absolute;
        z-index: 3;
        display: none;
    }
    &__alert {
        margin-top: 16px;
    }
    &.-disabled {
        .default-variation__overlay {
            display: block;
        }
    }
    &__block {
        margin-bottom: 32px !important;
    }
    .variations-table {
        .table {
            padding: 0 !important;
        }
    }
}
</style>
