var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{staticClass:"variations-table",attrs:{"show-toolbar":false,"show-selected-actions":false,"show-select-all":false,"columns":_vm.headers,"items":_vm.variations},scopedSlots:_vm._u([{key:"item.default",fn:function(ref){
var item = ref.item;
return [_c('input-radio',{attrs:{"return-value":true,"name":"default"},on:{"input":function($event){return _vm.changeDefaultVariation(item)}},model:{value:(item.default),callback:function ($$v) {_vm.$set(item, "default", $$v)},expression:"item.default"}})]}},(_vm.productId)?{key:"item.imageId",fn:function(ref){
var item = ref.item;
return [_c('product-image-field',{attrs:{"upload":false,"image-type":"product","name":"imageId","owner-id":_vm.productId,"is-popup":""},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item, true)}},model:{value:(item.imageId),callback:function ($$v) {_vm.$set(item, "imageId", $$v)},expression:"item.imageId"}})]}}:null,{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.sku.props,false),[_c('input-text',_vm._b({attrs:{"name":"sku"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}},model:{value:(item.sku),callback:function ($$v) {_vm.$set(item, "sku", $$v)},expression:"item.sku"}},'input-text',item.fields.sku.props,false))],1)]}},{key:"item.fullPrice",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.fullPrice.props,false),[_c('input-text',_vm._b({attrs:{"type":"number","measurement":_vm.currency,"name":"fullPrice"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item, true)}},model:{value:(item.fullPrice),callback:function ($$v) {_vm.$set(item, "fullPrice", _vm._n($$v))},expression:"item.fullPrice"}},'input-text',item.fields.fullPrice.props,false))],1)]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.discount.props,false),[_c('input-text',_vm._b({attrs:{"name":"discount"},nativeOn:{"input":function($event){return _vm.controlDiscountInput($event, item)}},model:{value:(item.discount),callback:function ($$v) {_vm.$set(item, "discount", $$v)},expression:"item.discount"}},'input-text',item.fields.discount.props,false))],1)]}},{key:"item.discountPrice",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.discountPrice.props,false),[_c('input-text',{attrs:{"disabled":"","name":"discountPrice","measurement":_vm.currency,"value":item.getFinalPrice()},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}}})],1)]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.stock.props,false),[_c('input-text',{attrs:{"type":"number","name":"stock","null-value":"∞"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}},model:{value:(item.stock),callback:function ($$v) {_vm.$set(item, "stock", $$v)},expression:"item.stock"}})],1)]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.size.props,false),[_c('product-size-input',_vm._b({attrs:{"disabled":"","name":"size","default-value":_vm.product.size,"value":{ length: item.length, width: item.width, height: item.height }},on:{"input":function($event){return _vm.updateVariationSize($event, item)},"update-field":function($event){return _vm.onUpdateFieldValue($event, item, true)}}},'product-size-input',item.fields.size.props,false))],1)]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.weight.props,false),[_c('input-text',_vm._b({attrs:{"name":"weight"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}},'input-text',item.fields.weight.props,false))],1)]}},{key:"item.gtin",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.gtin.props,false),[_c('input-text',_vm._b({attrs:{"name":"gtin"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}},model:{value:(item.gtin),callback:function ($$v) {_vm.$set(item, "gtin", $$v)},expression:"item.gtin"}},'input-text',item.fields.gtin.props,false))],1)]}},{key:"header.stock",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.columns.quantity'))+" "),_c('tooltip-popup',[_c('span',[_vm._v(_vm._s(_vm.$t('entities.productVariation.tooltips.quantity')))])])]},proxy:true},{key:"header.size",fn:function(){return [[_vm._v(" "+_vm._s(_vm.$t('lists.columns.size'))+" "),_c('tooltip-popup',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('entities.product.tooltips.size'))}})])]]},proxy:true},{key:"header.discount",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.columns.discount'))+" "),_c('tooltip-popup',[_c('span',[_vm._v(_vm._s(_vm.$t('entities.productVariation.tooltips.discount')))])])]},proxy:true},(!_vm.productId)?{key:"footer",fn:function(){return [_c('alert',{staticClass:"variations-table__alert"},[_vm._v(" "+_vm._s(_vm.$t('entities.productVariation.tooltips.pictures'))+" ")])]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }