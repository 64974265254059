<template>
    <div v-click-outside="closeDropdown" :name="name" @click="openDropdown" class="combobox-multiple" :class="classes">
        <div class="combobox-multiple__content">
            <template>
                <draggable
                    ghost-class="ghost"
                    tag="div"
                    drag-class="ghost"
                    :animation="200"
                    chosen-class="ghost"
                    :disabled="!draggable"
                    v-model="selected"
                    @end="handleDragEnd"
                    handle=".chip__drag-handle"
                    class="combobox-multiple__draggable"
                >
                    <chip
                        v-for="item in selected"
                        :key="item.id"
                        @click.stop
                        :class="{ '-error': !checkType(item.text) }"
                        class="combobox-multiple__chip chip"
                    >
                        <span
                            slot="prepend"
                            v-if="draggable && (!editingItem || editingItem.id !== item.id)"
                            class="chip__drag-handle material-symbols-outlined"
                        >
                            drag_indicator
                        </span>
                        <editable-text
                            v-if="editable"
                            :text="item.text"
                            @start-editing="editingItem = item"
                            @cancel-editing="editingItem = null"
                            @save="handleValueEdit($event, item)"
                            class="chip__editable-text"
                        ></editable-text>
                        <span v-else>{{ item.text }}</span>
                        <span
                            slot="append"
                            @click.stop="removeOption(item)"
                            v-if="!editingItem || editingItem.id !== item.id"
                            class="chip__icon material-symbols-outlined"
                        >
                            delete
                        </span>
                    </chip>
                </draggable>
            </template>
            <input
                :disabled="disabled"
                v-show="isActive"
                class="combobox-multiple__field"
                @keydown.enter="addNewItem"
                v-model="newItem"
                :class="title.type"
                ref="input"
            />
            <span
                v-if="hasDropdown"
                class="combobox-multiple__arrow icon -outlined"
                @click.stop="toggleDropdown"
                :class="{ 'is-open': isDropdownShow }"
            >
                expand_more
            </span>
        </div>
        <div
            v-if="hasDropdown"
            class="combobox-multiple__dropdown"
            :class="{ 'is-open': isDropdownShow, '-top': dropdownDirectionTop }"
        >
            <div
                v-for="option in searched"
                :key="option.id"
                class="combobox-multiple__option"
                :class="{ '-selected': checkIfSelected(option) }"
                @click.stop="toggleOption(option)"
            >
                <span v-if="checkIfSelected(option)" class="icon -selected">
                    check_box
                </span>
                <span v-else class="icon -outlined">
                    check_box_outline_blank
                </span>
                <div class="option__text" v-html="highlight(option.text)"></div>
            </div>
            <div class="combobox-multiple__option" v-if="!options.length">{{ $t('search.addValues') }}</div>
            <div class="combobox-multiple__option" v-else-if="!searched.length">{{ $t('search.noData') }}</div>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { cloneDeep, isEqual } from 'lodash';
import { uuid } from 'vue-uuid';
import { escapeRegExp } from '@/helpers/utils';
import draggable from 'vuedraggable';
import EditableText from '@/components/common/EditableText';
import Chip from '@/components/common/Chip';
export default {
    name: 'ComboboxMultiple',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    components: { Chip, EditableText, draggable },
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'Choose' },
        errors: { type: [Array, Object], default: () => [] },
        items: { type: [Array], default: () => [] },
        itemsType: { type: String, default: 'string' },
        value: { type: [Array, String, Number] },
        itemValue: { type: [String, Function], default: 'value' },
        itemText: { type: [String, Function], default: 'text' },
        returnObject: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        draggable: { type: Boolean, default: false },
        editable: { type: Boolean, default: false },
        hasDropdown: { type: Boolean, default: true },
        sort: { type: Boolean, default: true },
    },

    data() {
        return {
            dropdownDirectionTop: false,
            isDropdownShow: false,
            options: [],
            selected: [],
            isActive: false,
            editingItem: null,
            newItem: '',
        };
    },
    computed: {
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-is-open': this.isDropdownShow,
                '-disabled': this.disabled,
            };
        },
        searched() {
            if (!this.newItem) return this.options;
            return this.options.filter((item) => {
                return item.text.toLowerCase().startsWith(this.newItem.toLowerCase());
            });
        },
        title() {
            // как бы тут не было значения 0, но пока оставлю
            const selectedItem = this.options.find((option) => this.value == option.value);
            if (selectedItem) return { type: '-value', text: this.$t(selectedItem.text) };
            if (this.placeholder) return { type: '-placeholder', text: this.$t(this.placeholder) };
            return { type: '-placeholder', text: this.name };
        },
    },

    methods: {
        highlight(text) {
            if (!this.newItem) {
                return text;
            }
            const escapedText = escapeRegExp(this.newItem);
            return text.replace(new RegExp(escapedText, 'gi'), (match) => {
                return '<span class="highlight">' + match + '</span>';
            });
        },
        handleValueEdit(text, item) {
            item.text = text;
            if (this.returnObject) {
                item.item[this.itemText] = text;
                this.model = this.selected.map((item) => item.item);
            } else {
                item[this.itemValue] = text;
                this.model = this.selected.map((item) => item.value);
            }
        },
        checkIfSelected(option) {
            return this.selected.find((item) => item.id === option.id);
        },
        addNewItem() {
            if (!this.newItem) return;
            const newOption = {
                id: uuid.v4(),
                text: this.newItem,
                item: this.newItem,
                isNew: true,
            };
            newOption.value =
                typeof this.itemValue === 'function'
                    ? this.itemValue(newOption)
                    : newOption[this.itemValue] || newOption;
            this.options.push(cloneDeep(newOption));
            this.selectOption(newOption);
            this.newItem = '';
        },
        activate() {
            if (this.disabled) return;
            this.isActive = true;
            this.$nextTick(() => this.$refs.input.focus());
        },
        deactivate() {
            if (this.newItem) this.addNewItem();
            this.isActive = false;
        },
        formatItems(options) {
            if (!options) return [];
            const result = options.map((item) => ({
                id: uuid.v4(),
                text: typeof this.itemText === 'function' ? this.itemText(item) : item[this.itemText] || item,
                value: typeof this.itemValue === 'function' ? this.itemValue(item) : item[this.itemValue] || item,
                item: cloneDeep(item),
            }));
            if (this.sort) {
                return result.sort((a, b) => `${a.text}`.localeCompare(b.text));
            }
            return result;
        },

        checkType(item) {
            if (this.itemsType === 'integer') {
                const number = Number(item);
                const string = String(item);
                return !isNaN(number) && !string.includes('.');
            }
            return true;
        },

        formatSelected(value) {
            if (!value) return [];
            const optionsToSearch = cloneDeep(this.options);
            return value.map((item) => {
                let searchedIndex;
                let searchedOption;
                optionsToSearch.forEach((option, index) => {
                    if (this.returnObject) {
                        if (isEqual(option.item, item)) {
                            searchedOption = option;
                            searchedIndex = index;
                        }
                    } else {
                        if (option.value === item) {
                            searchedOption = option;
                            searchedIndex = index;
                        }
                    }
                });
                optionsToSearch.splice(searchedIndex, 1);
                return searchedOption;
            });
        },

        toggleOption(option) {
            if (this.selected.find((item) => item.id === option.id)) {
                this.removeOption(option);
            } else {
                this.selectOption(option);
            }
            this.newItem = '';
            this.$refs.input.focus();
        },

        removeOption(option) {
            if (this.returnObject) {
                this.model = this.model.filter((item) => option.item.id !== item.id);
            } else {
                this.model = this.model.filter((value) => option.value !== value);
            }

            this.selected = this.selected.filter((item) => option.id !== item.id);
            this.$emit('remove-item', option);
        },

        selectOption(option) {
            if (this.returnObject) {
                this.model = this.model ? [...this.model, option.item] : [option.item];
            } else {
                this.model = this.model ? [...this.model, option.value] : [option.value];
            }
            this.selected = [...this.selected, option];
            this.$emit('select-item', option);
        },

        openDropdown() {
            this.chooseDropdownDirection();
            this.isDropdownShow = true;
            this.activate();
        },
        closeDropdown() {
            this.isDropdownShow = false;
            this.deactivate();
        },
        toggleDropdown() {
            this.isDropdownShow ? this.closeDropdown() : this.openDropdown();
        },
        chooseDropdownDirection() {
            const offsetTop = this.$refs.input.getBoundingClientRect().y;
            this.dropdownDirectionTop = window.innerHeight - offsetTop < 350;
        },
        handleDragEnd() {
            this.$emit(
                'drag-end',
                this.selected.map((item) => item.item)
            );
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
            this.$emit(
                'updateTypeErrors',
                this.selected.filter((item) => !this.checkType(item.text))
            );
        },
        items: {
            handler(newValue) {
                this.options = this.formatItems(newValue);
                this.selected = this.formatSelected(this.value);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.combobox-multiple {
    position: relative;
    padding: 0 $form-control-padding-horizontal;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    background: $form-control-bg;
    transition: $form-control-transition;

    .icon {
        font-size: 24px;
        &.-selected {
            color: var(--v-primary-base);
        }
    }
    input {
        border: none;
    }
    .option__text {
        padding-left: 15px;
        .highlight {
            background-color: var(--v-on-primary-medium-base);
        }
    }

    &__content {
        min-height: 38px;
        height: auto;
        overflow: hidden;
        padding: 7px 0;
        margin-right: 25px;
    }

    &__draggable {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    }

    &:hover,
    &:focus {
        border-color: $form-control-border-color-hover;
    }
    &.-is-open {
        z-index: 5;
    }
    cursor: pointer;

    &__field {
        display: block;
        height: 24px;
        outline: none !important;
        appearance: none;
        flex-shrink: 2;
        line-height: calc(#{$form-control-height} - 2px);
        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    &.-has-errors {
        border-color: $error;
    }

    &__arrow {
        position: absolute;
        right: 8px;
        top: 9px;
        translate: $transition-fast;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;

        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;
        max-height: 300px;
        overflow-y: auto;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }

        &.-top {
            bottom: 100%;
            top: auto;
            transform-origin: 0% 100%;
        }
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 40px 8px 12px;
        transition: $transition-fast;
        font-size: 16px;
        line-height: 32px;
        text-align: left;
        cursor: pointer;

        &.-selected {
            background-color: var(--v-primary-lighten-base);
        }
        &-icon {
            position: relative;
            top: 0;
            color: inherit;
            line-height: 24px;
            margin-right: 10px;
        }

        &:hover {
            background-color: #f4f6f7;
        }

        &.-current {
            border-bottom: 1px solid #e0e0e0;
        }

        &.-highlighted {
            color: #2979ff;
        }
    }

    &__chip {
        margin: 0 4px 0 4px;
        border-radius: 12px;
        height: 24px !important;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87) !important;
        transition-property: box-shadow, opacity, -webkit-box-shadow, background;
        background: #e6e8eb !important;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 20px;
        max-width: 100%;
        outline: none;
        padding: 0 12px;
        position: relative;
        text-decoration: none;
        transition-duration: 0.28s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        white-space: nowrap;
    }
    .chip {
        &__icon {
            font-size: 17px;
            display: block;
            margin-left: 8px;
            &:hover {
                opacity: 0.5;
            }
        }
        &.-error {
            background-color: rgba(237, 36, 73, 0.1) !important;
            color: var(--v-warning-base) !important;
        }
        &__drag-handle {
            cursor: pointer;
            font-size: 17px;
            display: block;
            &:active {
                cursor: grabbing;
            }
        }
    }

    .icon {
        &.-selected {
            color: var(--v-primary-base) !important;
        }
    }
    &.-disabled {
        background-color: rgba(0, 0, 0, 0.04);
        color: var(--v-on-surface-disabled-base);
        &:hover {
            border-color: #cececf !important;
            cursor: default;
        }
    }
}
</style>
