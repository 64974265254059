import ApiClient from '@/api/apiClient';
import { Redirect, RequestParams } from '@/services/services.types';
import CustomError from '@/entities/customError';

export const OBJECT_TYPES = [
    {
        text: 'Catalog',
        value: 'catalog',
    },
    {
        text: 'Product',
        value: 'product',
    },
    {
        text: 'Page',
        value: 'page',
    },
];

class RedirectService {
    /**
     * Получение редиректов
     * @param {RequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов редикректов
     */

    async getAll(params: RequestParams = {}): Promise<[CustomError | null, Redirect[] | []]> {
        const url = `/redirects`;
        const defaultValue = [] as [];
        const config = { params };
        const errorPath = '[api:redirect:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Получение конкретного редиректа
     * @param {String} id - id редиректа
     * @returns {Promise} - объект редиректа
     */

    async getOne(id: string): Promise<[CustomError | null, Redirect | null]> {
        const url = `/redirects/${id}`;
        const defaultValue = null;
        const errorPath = '[api:redirect:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание редиректа
     * @param {Redirect} redirect - объект редиректа
     * @returns {Promise} - объект созданного редиректа
     */

    async createOne(redirect: Redirect): Promise<[CustomError | null, Redirect | null]> {
        const url = '/redirects';
        const defaultValue = null;
        const errorPath = '[api:redirect:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: redirect });
    }

    /**
     * Обновление редиректа
     * @param {Redirect} redirect - объект редиректа
     * @returns {Promise} - объект обновлённого редиректа
     */

    async updateOne(redirect: Redirect): Promise<[CustomError | null, Redirect | null]> {
        const url = `/redirects/${redirect.id}`;
        const defaultValue = null;
        const errorPath = '[api:redirect:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: redirect });
    }

    /**
     * Удаление редиректа
     * @param {string} id - id редиректа
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/redirects/${id}`;
        const defaultValue = null;
        const errorPath = '[api:redirect:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }

    /**
     * Загрузка списка редиректов
     * @param {File} file - файл с редиректами
     * @returns {Promise} - null
     */

    async uploadFromFile(file: File): Promise<[CustomError | null, null]> {
        const url = `/redirects/import`;
        const defaultValue = null;
        const errorPath = '[api:redirect:uploadFromFile]';
        const formData = new FormData();
        formData.set('file', file);
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: formData });
    }
}

export default new RedirectService();
